import { BeatLoader } from 'react-spinners';
import styles from './LoadingCards.module.scss';

export const LoadingCards = () => {
  let numberOfCards = 3;

  return (
    <div className={styles.container}>
      {Array.from({ length: numberOfCards }).map((_, index) => (
        <BeatLoader color="#ffbc6d" key={index} className={styles.loader} />
      ))}
    </div>
  );
};
