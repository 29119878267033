'use client';

import Image from 'next/image';
import { useTranslations } from 'next-intl';

import Button from '@/components/ui/Button';
import Icon from '@/components/ui/Icon';

import styles from './PresenceSection.module.scss';
import { buildSrcUrl } from '@/utils/url';

const PresenceSection = ({ data, imageUrl }) => {
  const t = useTranslations('PresenceSection');

  return (
    <div className={styles.presence}>
      {/* <code>{JSON.stringify(data)}</code> */}
      <div className={styles[`presence__col-left`]}>
        <h2 className="title title--xlarge">{data.title}</h2>
        <p className="text text--medium">{data.description}</p>

        {data.cta && (
          <Button
            href={data.cta.url}
            variant="text"
            size="fit"
            transform="uppercase"
          >
            {data.cta.text}
            <Icon
              name="arrow_forward"
              style={{ marginLeft: 16, fontSize: 28 }}
            />
          </Button>
        )}
      </div>
      <div className={styles[`presence__col-right`]}>
        <Image
          src={imageUrl ?? '/img/for-you.jpeg'}
          sizes="auto"
          alt="For you"
          fill
        />
      </div>
    </div>
  );
};

export default PresenceSection;
