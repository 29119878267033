import { SearchLocationFields } from '@/contexts/SearchContext/types';
import { mobilitySearch } from './search';
import qs from 'qs';
import { MobilityCarProps } from '@/types/mobility';

interface CarsGroup {
  Cars: MobilityCarProps[];
}

interface Availabilities {
  CarsGroups: CarsGroup[];
}

interface SearchData {
  search?: {
    Availabilities: Availabilities;
  };
}
export interface RecommendedCarsResponse {
  response: SearchData;
  searchParams: string;
}

export const getRecommendedCars =
  async (): Promise<RecommendedCarsResponse> => {
    const login = process.env.MOBILITY_LOGIN || '';
    const password = process.env.MOBILITY_PASSWORD || '';

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const params = {
      pickup: {
        city: 'MIAMI / FL, ESTADOS UNIDOS - MIAMI INTERNATIONAL (MIA)',
        datetime: today.toISOString(),
        iata: 'MIA',
        type: 'airport',
      } as SearchLocationFields,
      return: {
        city: 'MIAMI / FL, ESTADOS UNIDOS - MIAMI INTERNATIONAL (MIA)',
        datetime: tomorrow.toISOString(),
        iata: 'MIA',
        type: 'airport',
      } as SearchLocationFields,
    };

    const searchParams = qs.stringify(params);

    const response = await mobilitySearch({
      Login: login,
      Password: password,
      ...params,
    });

    if (!response) {
      throw new Error('No response from mobilitySearch');
    }

    return { response, searchParams };
  };
