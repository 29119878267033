'use client';

import { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Swiper, SwiperSlide, SwiperClass } from 'swiper/react';

import SectionHeader, {
  SectionHeaderProps,
} from '@/components/ui/SectionHeader';
import Button from '@/components/ui/Button';
import Icon from '@/components/ui/Icon';

import styles from './ExperiencesCategoriesList.module.scss';
import { buildSrcUrl } from '@/utils/url';

interface ExperiencesCategoriesListProps
  extends Omit<SectionHeaderProps, 'children'> {
  extraClasses?: string;
  data: any;
}

const ExperiencesCategoriesList = ({
  title,
  subtitle,
  color,
  titleSize = 'xlarge',
  extraClasses,
  data,
}: ExperiencesCategoriesListProps) => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(
    null,
  );

  return (
    <section className={`${styles.experiencesCategoriesList} ${extraClasses}`}>
      <SectionHeader
        title={title}
        titleSize={titleSize}
        subtitle={subtitle}
        color={color}
      >
        <div className={styles.arrows}>
          <Button
            variant="text"
            color="secondary"
            transform="uppercase"
            size="fit"
            onClick={() => swiperInstance?.slidePrev()}
          >
            <Icon name="arrow_back" />
          </Button>
          <Button
            variant="text"
            color="secondary"
            transform="uppercase"
            size="fit"
            onClick={() => swiperInstance?.slideNext()}
          >
            <Icon name="arrow_forward" />
          </Button>
        </div>
      </SectionHeader>

      <Swiper
        onSwiper={(swiper) => setSwiperInstance(swiper)}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 24,
          },
          990: {
            slidesPerView: 3,
            spaceBetween: 24,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 24,
          },
        }}
        className={styles['experiencesCategoriesList__list']}
      >
        {data.map((item: any) => (
          <SwiperSlide
            key={item.id}
            className={styles['experiencesCategoriesList__card']}
          >
            <div className={styles['experiencesCategoriesList__image']}>
              <Image
                src={
                  buildSrcUrl(item.attributes.cover.data.attributes.url) ??
                  '/img/for-you.jpeg'
                }
                fill
                sizes="auto"
                alt={item.attributes.title}
              />
            </div>
            <Link
              className={styles['experiencesCategoriesList__link']}
              href={`/experiences/${item.attributes.slug}`}
            >
              {item.attributes.title}
              <span className={styles['experiencesCategoriesList__icon']}>
                <Icon name="arrow_outward" />
              </span>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default ExperiencesCategoriesList;
