'use client';

import { useTranslations } from 'next-intl';
import { useState, useEffect } from 'react';
import SectionHeader, {
  SectionHeaderProps,
} from '@/components/ui/SectionHeader';
import Button from '@/components/ui/Button';
import Icon from '@/components/ui/Icon';
import styles from './CarsRecomendationsList.module.scss';
import { RecommendedCarsCard } from '../RecommendedCarsCard';
import { LoadingCards } from '../LoadingCards';
import {
  getRecommendedCars,
  RecommendedCarsResponse,
} from '@/app/_actions/recommended-cars';

interface CarsRecomendationsListProps
  extends Omit<SectionHeaderProps, 'children'> {
  extraClasses?: string;
}

const CarsRecomendationsList = ({
  title,
  subtitle,
  color,
  titleSize,
  extraClasses,
}: CarsRecomendationsListProps) => {
  const t = useTranslations('common');
  const [recommendedCars, setRecommendedCars] =
    useState<RecommendedCarsResponse | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCars = async () => {
      try {
        const response = await getRecommendedCars();
        setRecommendedCars(response);
      } catch (error) {
        console.error('Error fetching recommended cars:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCars();
    return () => setRecommendedCars(null);
  }, []);

  if (isLoading) {
    return <LoadingCards />;
  }

  return (
    <section className={`${styles.recomendationsList} ${extraClasses}`}>
      <SectionHeader
        title={title}
        titleSize={titleSize}
        subtitle={subtitle}
        color={color}
      >
        <Button variant="text" transform="uppercase" size="fit" href="/search">
          {t('see_options')} <Icon name="arrow_forward" />
        </Button>
      </SectionHeader>
      <div className={styles.loaderContainer}>
        <RecommendedCarsCard recommendedCars={recommendedCars} />
      </div>
    </section>
  );
};

export default CarsRecomendationsList;
