'use client';

import { useState } from 'react';
import * as Collapsible from '@radix-ui/react-collapsible';

import styles from './ListCollapse.module.scss';

const titleSizes = {
  xlarge: 'title--xlarge',
  large: 'title--large',
};

export interface ListCollapseProps {
  title: string;
  titleSize?: keyof typeof titleSizes;
  extraClasses?: string;
  items: CollapseItemProps[];
}

export function ListCollapse({
  title,
  titleSize = 'xlarge',
  extraClasses,
  items,
}: ListCollapseProps) {
  return (
    <section className={`${styles.listCollapse} ${extraClasses}`}>
      <h2
        className={`title ${titleSizes[titleSize]}`}
        dangerouslySetInnerHTML={{ __html: title }}
      />

      <div className={styles['listCollapse__list']}>
        {items.map((item: CollapseItemProps, index) => (
          <CollapseItem key={`list-collapse-item-${index}`} {...item} />
        ))}
      </div>
    </section>
  );
}

export default ListCollapse;

export interface CollapseItemProps {
  title: string;
  text: string;
  open?: boolean;
}

export function CollapseItem({
  title,
  text: content,
  open = false,
}: CollapseItemProps) {
  const [isOpen, setIsOpen] = useState(open);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Collapsible.Root
      className={`
        ${styles.collapseItem}
        ${isOpen && styles['collapseItem--opened']}
      `}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      <Collapsible.Trigger
        onClick={toggleOpen}
        className={`title title--medium ${styles['collapseItem__title']}`}
      >
        {title}
      </Collapsible.Trigger>

      <Collapsible.Content className={styles.CollapsibleContent}>
        <p className="text" dangerouslySetInnerHTML={{ __html: content }}></p>
      </Collapsible.Content>
    </Collapsible.Root>
  );
}
