'use client';

import { useState } from 'react';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import Link from 'next/link';
import { Swiper, SwiperSlide, SwiperClass } from 'swiper/react';

import Button from '@/components/ui/Button';
import Icon from '@/components/ui/Icon';
import SectionHeader from '@/components/ui/SectionHeader';

import styles from './BlogPostsList.module.scss';
import { buildSrcUrl } from '@/utils/url';

const BlogPostsList = ({ data }: any) => {
  const t = useTranslations('Home.BlogPostsList.b2c');
  const { links, header } = data;
  const title = header.heading;
  const [postHighlight, ...posts] = links.data;

  const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(
    null,
  );

  return (
    <section className={`container ${styles.blogPostsList}`}>
      <SectionHeader title={title} titleSize="large">
        {header.cta && (
          <Button
            href={header.cta.url}
            variant="contained"
            transform="uppercase"
          >
            {header.cta.text}
          </Button>
        )}
      </SectionHeader>

      <div className={styles.postHighlight}>
        {postHighlight && postHighlight?.attributes.cover && (
          <Image
            src={`${buildSrcUrl(
              postHighlight?.attributes.cover.data.attributes.url,
            )}`}
            alt="Blog post"
            width={651}
            height={352}
            className={styles['postHighlight__image']}
          />
        )}

        <div className={styles['postHighlight__content']}>
          <p>{postHighlight?.attributes.date || ''}</p>
          <h3 className="title title--medium">
            {postHighlight?.attributes.title || ''}
          </h3>
          <p className="text">{postHighlight?.attributes.summary || ''}</p>

          <Button
            href={`/blog/post/${postHighlight?.attributes.slug}`}
            variant="text"
            size="fit"
          >
            {t('read_more')}
          </Button>
        </div>
      </div>

      <div className={styles.posts}>
        <div className={styles['posts__header']}>
          <h3 className="title">{header.Summary}</h3>
          <div className={styles['posts__actions']}>
            <Button
              variant="text"
              color="secondary"
              transform="uppercase"
              size="fit"
              onClick={() => swiperInstance?.slidePrev()}
            >
              <Icon name="arrow_back" />
            </Button>
            <Button
              variant="text"
              color="secondary"
              transform="uppercase"
              size="fit"
              onClick={() => swiperInstance?.slideNext()}
            >
              <Icon name="arrow_forward" />
            </Button>
          </div>
        </div>
        <Swiper
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 24,
            },
            990: {
              slidesPerView: 2,
              spaceBetween: 24,
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 24,
            },
          }}
          className={styles['posts__list']}
        >
          {posts.map((item: any, idx: number) => (
            <SwiperSlide
              key={`blog-carousel-posts-${idx}`}
              className={styles['posts__item']}
            >
              <Image
                src={buildSrcUrl(item.attributes.cover.data.attributes.url)}
                alt="Blog post 2"
                width={438}
                height={185}
              />
              <Link href={`/blog/post/${item.attributes.slug}`}>
                <h4>{item.attributes.title}</h4>
                <p className="text text--weight-400">
                  {item.attributes.summary}
                </p>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default BlogPostsList;
