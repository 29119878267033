'use client';

import { useEffect, useState } from 'react';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { Swiper, SwiperSlide, SwiperClass } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

import Button from '@/components/ui/Button';
import Icon from '@/components/ui/Icon';

import styles from './Testimonials.module.scss';
import { getTestimonialsList } from '@/services/strapi-api/testimonialsList';
import { localesTypes } from '@/config/locales';

export type TestimonialList = {
  id: number;
  attributes: {
    testimonials: TestimonialData[] | null;
  };
};

export type TestimonialData = {
  id: number;
  agent_name: string;
  agent_role: string;
  agent_company: string;
  agent_img: {
    data: {
      attributes: {
        name: string;
        url: string;
      };
    };
  };
  // agent_image: string;
  content: string;
};

const Testimonials = ({ locale }: { locale: localesTypes }) => {
  const t = useTranslations('Home.Testimonials');
  const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(
    null,
  );
  const [testimonials, setTestimonials] = useState<TestimonialList[] | null>(
    null,
  );
  const cmsUrl = process.env.NEXT_PUBLIC_STRAPI_CMS_URL;

  useEffect(() => {
    const getTestimonials = async () => {
      const testimonials = await getTestimonialsList({ locale });
      setTestimonials(testimonials);
    };
    getTestimonials();
  }, [locale]);

  return (
    <section className={styles.testimonials}>
      <div className="container">
        <h5 className="subtitle">{t('title')}</h5>
        <h2 className="title title--large">{t('subtitle')}</h2>

        <div className={styles['testimonials__list-wrapper']}>
          <Button
            variant="text"
            color="secondary"
            transform="uppercase"
            size="fit"
            onClick={() => swiperInstance?.slidePrev()}
          >
            <Icon name="arrow_back" style={{ fontSize: 28 }} />
          </Button>
          <Swiper
            onSwiper={(swiper) => setSwiperInstance(swiper)}
            spaceBetween={16}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 16,
              },
              990: {
                slidesPerView: 2,
                spaceBetween: 16,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 16,
              },
            }}
            className={styles['testimonials__list']}
            loop={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
          >
            {testimonials &&
              testimonials.length > 0 &&
              testimonials?.map((item) => {
                console.log(item);
                const testimonial = item.attributes.testimonials?.[0];
                const imageUrl = testimonial?.agent_img?.data?.attributes?.url;

                return (
                  <SwiperSlide
                    key={item.id}
                    className={styles.card}
                    onMouseEnter={() => swiperInstance?.autoplay.stop()}
                    onMouseLeave={() => swiperInstance?.autoplay.start()}
                  >
                    <div className={styles['card__header']}>
                      <figure className={styles['card__figure']}>
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="100%"
                            height="100%"
                            viewBox="0 0 79 79"
                            fill="none"
                          >
                            <path
                              d="M79 39.5C79 47.3124 76.6834 54.9493 72.343 61.445C68.0027 67.9408 61.8337 73.0036 54.616 75.9932C47.3983 78.9829 39.4562 79.7651 31.7939 78.241C24.1317 76.7169 17.0935 72.9549 11.5693 67.4307C6.04511 61.9065 2.2831 54.8683 0.758981 47.2061C-0.765135 39.5438 0.017097 31.6017 3.00676 24.384C5.99642 17.1663 11.0592 10.9973 17.555 6.65695C24.0507 2.31663 31.6876 -9.31615e-08 39.5 0V10.1063C33.6865 10.1063 28.0035 11.8302 23.1697 15.06C18.3359 18.2898 14.5685 22.8805 12.3437 28.2515C10.119 33.6225 9.5369 39.5326 10.6711 45.2344C11.8052 50.9363 14.6047 56.1737 18.7155 60.2845C22.8263 64.3953 28.0637 67.1948 33.7656 68.3289C39.4674 69.4631 45.3775 68.881 50.7485 66.6563C56.1195 64.4315 60.7102 60.6641 63.94 55.8303C67.1698 50.9965 68.8937 45.3135 68.8937 39.5H79Z"
                              fill="#FE5A00"
                            />
                          </svg>
                        </span>
                        <Image
                          src={
                            imageUrl
                              ? `${cmsUrl}${imageUrl}`
                              : '/img/demo/avatar-1.jpeg'
                          }
                          width={60}
                          height={60}
                          alt="Agencia"
                        />
                      </figure>
                      <div>
                        <h4>{testimonial?.agent_name ?? 'nome'}</h4>
                        <p className={styles['card__header--role']}>
                          {testimonial?.agent_role ?? 'cargo'}
                        </p>
                        <p className={styles['card__header--company']}>
                          {testimonial?.agent_company ?? 'agencia'}
                        </p>
                      </div>
                    </div>
                    <p className={styles['card__content']}>
                      {testimonial?.content ?? 'testemunho'}
                    </p>
                  </SwiperSlide>
                );
              })}
          </Swiper>
          <Button
            variant="text"
            color="secondary"
            transform="uppercase"
            size="fit"
            onClick={() => swiperInstance?.slideNext()}
          >
            <Icon
              name="arrow_forward"
              style={{ marginLeft: 16, fontSize: 28 }}
            />
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
