'use client';

import { MobilityCarProps } from '@/types/mobility';
import styles from './RecommendedCarsCard.module.scss';
import CarCard from '../CarCard';
import { RecommendedCarsResponse } from '@/app/_actions/recommended-cars';

export const RecommendedCarsCard = ({
  recommendedCars,
}: {
  recommendedCars: RecommendedCarsResponse | null;
}) => {
  if (!recommendedCars) return null;

  const carList =
    recommendedCars.response?.search?.Availabilities?.CarsGroups?.[0]?.Cars.filter(
      (car: MobilityCarProps) =>
        car.CarCategory === 'Conversivel' ||
        car.CarCategory === 'Intermediário' ||
        car.CarCategory === 'SUV',
    );

  if (!carList) return null;

  return (
    <div className={styles['recomendationsList__list']}>
      {carList?.map((car: MobilityCarProps, index: number) => {
        if (index < 3) {
          return (
            <CarCard
              href={`/search/MIAMI?${recommendedCars.searchParams}`}
              showButton
              data={car}
              key={`${index}-${car.CarCategory}`}
            />
          );
        }
        return null;
      })}
    </div>
  );
};
