'use client';

import { useTranslations } from 'next-intl';

import { useAuth } from '@/contexts/AuthContext';

import Button from '@/components/ui/Button';
import Image from 'next/image';
import styles from './AccessExplore.module.scss';

interface AccessExploreProps {
  extraClasses?: string;
}

const AccessExplore = ({ extraClasses }: AccessExploreProps) => {
  const t = useTranslations('AccessExplore');
  const { openLoginModal, openRegisterModal } = useAuth();

  return (
    <section className={`${styles.accessExplore} ${extraClasses}`}>
      <div className="container">
        <figure className={styles['accessExplore__image']}>
          <Image
            src="/img/access-explore.jpeg"
            alt="Acesse e explore"
            fill
            style={{
              objectFit: 'cover',
            }}
          />

          {/* <img src="/img/access-explore.jpeg" alt="Acesse e explore" /> */}
        </figure>

        <div className={styles['accessExplore__content']}>
          <h2 className="title title--color-light-50">{t('homeb2c.title')}</h2>
          <p className="text text--medium text--color-light-50">
            {t('homeb2c.description')}
          </p>
          <ul className={styles['accessExplore__actions']}>
            <li>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => openRegisterModal(true)}
              >
                {t('homeb2c.cta_register')}
              </Button>
            </li>
            <li>
              <Button
                variant="contained"
                color="secondary-inverse"
                onClick={() => openLoginModal(true)}
              >
                {t('homeb2c.cta_login')}
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default AccessExplore;
