'use client';

import { useTranslations } from 'next-intl';

import { setUserType } from '@/app/_actions/pre-home';

import Button from '@/components/ui/Button';
import Image from 'next/image';
import styles from './PreHome.module.scss';

const PreHome = () => {
  const t = useTranslations('PreHome');

  const handleSetCookie = async (userType: 'customer' | 'agent') => {
    await setUserType(userType);
  };

  return (
    <main className={`container ${styles.preHome}`}>
      <div className={`${styles['preHome__card']} `}>
        <Image
          src="/img/for-you-cover.jpeg"
          alt="For you"
          width={764}
          height={913}
          quality={100}
          priority
          className={styles['preHome__image--for-you']}
        />
        <h2 className={styles['preHome__title']}>{t('customer.title')}</h2>
        <p
          className={styles['preHome__description']}
          dangerouslySetInnerHTML={{ __html: t.raw('customer.description') }}
        />

        <Button transform="initial" onClick={() => handleSetCookie('customer')}>
          {t('customer.cta')}
        </Button>
      </div>
      <div className={`${styles['preHome__card']} `}>
        <Image
          src="/img/for-agent-cover.jpeg"
          alt="For agent"
          width={764}
          height={913}
          quality={100}
          priority
          className={styles['preHome__image--for-agent']}
        />
        <h2 className={styles['preHome__title']}>{t('agent.title')}</h2>
        <p
          className={styles['preHome__description']}
          dangerouslySetInnerHTML={{ __html: t.raw('agent.description') }}
        />

        <Button
          color="secondary"
          transform="initial"
          onClick={() => handleSetCookie('agent')}
        >
          {t('agent.cta')}
        </Button>
      </div>
    </main>
  );
};

export default PreHome;
