import { localesTypes } from '@/config/locales';
import { strapiApi } from '../api';
import { apiId } from '@/constants/pathnames';
import qs from 'qs';
import { TestimonialList } from '@/components/ui/Testimonials';

export const getTestimonialsList = async ({
  locale,
}: {
  locale: localesTypes;
}): Promise<TestimonialList[] | null> => {
  const objQuery: any = {
    fields: ['*'],
    populate: 'deep, 3',
    nested: true,
    locale: locale === 'pt-br' ? 'pt-BR' : locale,
  };

  const query = qs.stringify(objQuery, {
    encodeValuesOnly: true,
  });

  const url = `${apiId.testimonialsList}?${query}`;

  try {
    const response = await strapiApi.get(url);
    return response.data.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
